import React, { useState, useEffect } from "react";
import "../styles/CookiePopup.css";

const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem("cookiesAccepted", "true");
  };

  const handleOpenPopup = () => {
    setIsVisible(true);
  };

  return (
    <>
      {/* Pulsante flottante */}
      <button className="cookie-button" onClick={handleOpenPopup}>
        <span>&#x25B2;</span> {/* Icona triangolare */}
      </button>

      {/* Popup */}
      {isVisible && (
        <div className="cookie-popup-overlay" onClick={() => setIsVisible(false)}>
          <div
            className="cookie-popup-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Informativa sui Cookie</h2>
            <p>
              Questo sito utilizza esclusivamente cookie tecnici di terze parti per garantire il corretto funzionamento del sito 
              e migliorare la tua esperienza. <br /> Questi cookie sono essenziali e non possono essere disabilitati poiché necessari per 
              il funzionamento del sito. <br /> Per ulteriori informazioni, consulta la nostra <br />
              <a href="/CookiePolicy">Cookie policy</a>.
            </p>
            <button className="cookie-popup-accept" onClick={handleAccept}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiePopup;
