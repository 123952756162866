import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_italy from "@amcharts/amcharts5-geodata/italyHigh";
import { useNavigate } from "react-router-dom";
import '../styles/Mappa.css';

const Mappa = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    let root = am5.Root.new("mappa");

    // Crea un grafico della mappa
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoMercator(),
        panX: "none",
        panY: "none", 
        wheelX: "none",
        wheelY: "none", 
        zoomLevel: 1,
        maxZoomLevel: 1, 
      })
    );

    // Aggiungi la serie della mappa con i dati geografici dell'Italia
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_italy,
        include: [
          "IT-25", // Lombardia
          "IT-23", // Valle d'Aosta
          "IT-21", // Piemonte
          "IT-34", // Friuli-Venezia Giulia
          "IT-32", // Trentino-Alto Adige
          "IT-36", // Veneto
          "IT-42", // Liguria
          "IT-45", // Emilia-Romagna
          "IT-52", // Toscana
          "IT-57", // Marche
          "IT-55", // Umbria
        ],
      })
    );

    // Configura l'aspetto delle regioni
    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color(0xcccccc),
      stroke: am5.color(0x000000),
    });

    // Gestisci il click sulle regioni
    polygonSeries.mapPolygons.template.events.on("click", function (ev) {
      const regionName = ev.target.dataItem.dataContext.name; // Nome della regione
      const regionCode = ev.target.dataItem.dataContext.id.split("-")[1].toLowerCase(); // Codice della regione
      navigate(`../eventi/${regionName}`); // Reindirizza alla pagina dinamica della regione
    });

    return () => {
      root.dispose(); // Pulisci la mappa al dismount del componente
    };
  }, [navigate]);

  return (
    <div className="padding">
      <h1>I PROSSIMI APPUNTAMENTI</h1>
      <h2>Clicca sulla tua regione o vai all'elenco completo</h2>
      <div id="mappa"></div>
        <button
          className="btn-complete-list"
          style={{ marginTop: "20px" }}
          onClick={() => navigate("../eventi")}
        >
          VAI ALL'ELENCO COMPLETO
        </button>
    </div>
  );
};

export default Mappa;




