import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isTransparent, setIsTransparent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY > 30);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <header id="navbar" className={`navbar ${isTransparent ? 'transparent' : ''} ${isOpen ? 'open' : ''}`}>
      <div className="left-buttons">
      <Link to="/Fondatori"><button className="button-3">I Fondatori</button></Link>
      <Link to="/punti-programmatici"><button className="button-5">Punti Programmatici</button></Link>
      </div>
     { /* <div className="left-buttons">
        <Link to="/scrivici"><button className="button-3">Scrivici</button></Link>
        <div className="dropdown">
          <button className="dropbtn">
            Trasparenza <img className="icon" src="/assets/icon/freccia.svg" alt="freccia" />
          </button>
          <div className="dropdown-content">
            <Link to="/statuto">Lo Statuto</Link>
          </div>
        </div>
        <Link to="/sostienici"><button className="button-2">DONA</button></Link>
      </div> */}

      <div className="logo1">
        <Link to="/"><img src="/assets/images/logo.png" alt="Logo" /></Link>
      </div>

      <div className="right-button">
      {/*  <Link to="/notizie"><button className="button-2">Notizie</button></Link>
        <Link to="/contatti"><button className="button-2">Contatti</button></Link> */}
        <Link to="/Manifesti"><button className="button-3">Download</button></Link>
        <Link to="/tesserati"><button className="button-4">Diventa Sostenitore <img className="icon" src="/assets/icon/freccia.svg" alt="" /></button></Link>
      </div>

      <div className={`menu-icon ${isOpen ? 'visible' : ''}`} onClick={toggleMenu}>
        &#9776;
      </div>

      {/* Menu a tendina */}
      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
       { /*<Link to="/scrivici" className="dropdown-item">SCRIVICI</Link>
        <Link to="" className="dropdown-item" onClick={toggleSubMenu}>
          TRASPARENZA
          <img className="icon" src="/assets/icon/freccia.svg" alt="freccia" style={{ marginLeft: '10px' }} />
        </Link> */}

        {/* Sottomenu per "TRASPARENZA" */}
        {/*
        {isSubMenuOpen && (
      <div className="sub-dropdown-content">
        <Link to="/statuto" className="dropdown-item">Statuto</Link>
        <Link to="/statuto/sezione1" className="dropdown-item">2</Link>
        {/* Aggiungi più pulsanti qui se necessario 
      </div> 
)}*/}


       {/* <Link to="/sostienici" className="dropdown-item">DONA</Link>
        <Link to="/notizie" className="dropdown-item">NOTIZIE</Link>
        <Link to="/contatti" className="dropdown-item">CONTATTI</Link> */}
        <Link to="/Fondatori" className="dropdown-item">I Fondatori</Link>
        <Link to="/Manifesti" className="dropdown-item">Download</Link>
        <Link to="/punti-programmatici" className="dropdown-item">Punti Programmatici</Link>
        <Link to="/tesserati" className="dropdown-item">DIVENTA SOSTENITORE</Link>
      </div>
    </header>
  );
};

export default Navbar;








