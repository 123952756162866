import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PuntiProgrammaticiCard from '../components/PuntiProgrammaticiCard';
import CookiePopup from '../components/CookiePopup';



const PuntiProgrammatici = () => {
  return (
    <div>
      <Navbar />
      <PuntiProgrammaticiCard />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default PuntiProgrammatici;