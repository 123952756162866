import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import axios from 'axios';
import provinces from '../utils/Province';
import CodiceFiscaleCalculator from '../utils/CodiceFiscaleCalculator';
import '../styles/ContactFormTesseramento.css';
import provinceTutte from '../utils/provinceTutte';

function ContactFormTesseramento({ onOpenRegolamento, onOpenStatuto }) {
  const [supporterType, setSupporterType] = useState('sostenitore');
  const [cost, setCost] = useState(15);
  const [tesseraOption, setTesseraOption] = useState('ritiro');
  const [differentAddress, setDifferentAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('bonifico');
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState(''); 
  const [dataNascita, setDataNascita] = useState('');
  const [natoA, setNatoA] = useState('');
  const [provinciaNascita, setProvinciaNascita] = useState('');
  const [codiceFiscale, setCodiceFiscale] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [successMessage, setSuccessMessage] = useState(''); 
  const [privacy, setPrivacy] = useState(false);
  const [consenso, setConsenso] = useState(false);
  const [professione, setProfessione] = useState('');
  const [titoloStudio, setTitoloStudio] = useState('');
  const [cap, setCap] = useState('');
  const [indirizzo, setIndirizzo] = useState('');
  const [comuneResidenza, setComuneResidenza] = useState('');
  const [provinciaResidenza, setProvinciaResidenza] = useState('');
  const [shippingNome, setShippingNome] = useState('');
  const [shippingCognome, setShippingCognome] = useState('');
  const [shippingIndirizzo, setShippingIndirizzo] = useState('');
  const [shippingAppartamento, setShippingAppartamento] = useState('');
  const [shippingCitta, setShippingCitta] = useState('');
  const [shippingProvincia, setShippingProvincia] = useState('');
  const [shippingCap, setShippingCap] = useState('');
  const [cartaIdentita, setCartaIdentita] = useState('');
  const [email, setEmail] = useState('');
  const [cellulare, setCellulare] = useState('');
  const [donation, setDonation] = useState(0); 
  const [customDonation, setCustomDonation] = useState(""); 

  const handleAddDonation = (amount) => {
    setDonation(amount);
    setCustomDonation("");
  };
  
  
  const handleCustomDonationChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setCustomDonation(value);
    setDonation(0);
  };
  
  
  const calculateTotalCost = () => {
  
    const additionalDonation = paymentMethod === "bonifico" ? (donation + (customDonation || 0)) : 0;
    return cost + additionalDonation;
  };
  
  


  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split('T')[0]; 
  };
 

 

  const handleSupporterTypeChange = (type, baseCost) => {
    setSupporterType(type);
    const shippingCost = tesseraOption === 'spedizione' ? 0 : 0;
    calculateTotalCost(baseCost, shippingCost);
  };

  const handleTesseraOptionChange = (option) => {
    setTesseraOption(option);
    const supporterCost = supporterType === 'sostenitore' ? 15 : 100;
    const shippingCost = option === 'spedizione' ? 0 : 0;
    calculateTotalCost(supporterCost, shippingCost);
  };

  const totalCost = calculateTotalCost();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const sanitizedData = {
      nome: DOMPurify.sanitize(nome.trim()),
      cognome: DOMPurify.sanitize(cognome.trim()),
      natoA: DOMPurify.sanitize(natoA.trim()),
      provinciaNascita: DOMPurify.sanitize(provinciaNascita.trim()),
      dataNascita: DOMPurify.sanitize(dataNascita.trim()),
      comuneResidenza: DOMPurify.sanitize(event.target.comuneResidenza?.value.trim() || ''),
      provinciaResidenza: DOMPurify.sanitize(event.target.provinciaResidenza?.value.trim() || ''),
      cap: DOMPurify.sanitize(event.target.cap?.value.trim() || ''),
      indirizzo: DOMPurify.sanitize(event.target.indirizzo?.value.trim() || ''),
      professione: event.target.Professione?.value.trim() 
        ? DOMPurify.sanitize(event.target.Professione.value.trim()) 
        : null,
      titoloStudio: event.target['Titolo di Studio']?.value.trim() 
        ? DOMPurify.sanitize(event.target['Titolo di Studio'].value.trim()) 
        : null,
      email: DOMPurify.sanitize(event.target.email?.value.trim() || ''),
      cellulare: DOMPurify.sanitize(event.target.cellulare?.value.trim() || ''),
      codiceFiscale: DOMPurify.sanitize(codiceFiscale.trim()),
      cartaIdentita: DOMPurify.sanitize(cartaIdentita.trim()),
      tesseraOption: tesseraOption === 'ritiro' 
      ? 'Ritiro tessera dal corrispondente territoriale' 
      : 'Ricevo a casa la tessera per posta',
      paymentMethod: paymentMethod === 'bonifico' 
      ? 'Bonifico Bancario' 
      : 'Pagamento in contanti',
      privacy: event.target.privacy?.checked || false,
      consenso: event.target.consenso?.checked || false,
      cost: calculateTotalCost(),
      //consenso2: event.target.consenso2?.checked || false,

      shippingNome: differentAddress ? DOMPurify.sanitize(event.target.shippingNome?.value.trim() || null) : null,
      shippingCognome: differentAddress ? DOMPurify.sanitize(event.target.shippingCognome?.value.trim() || null) : null,
      shippingIndirizzo: differentAddress ? DOMPurify.sanitize(event.target.shippingIndirizzo?.value.trim() || null) : null,
      shippingAppartamento: differentAddress ? DOMPurify.sanitize(event.target.shippingAppartamento?.value.trim() || null) : null,
      shippingCitta: differentAddress ? DOMPurify.sanitize(event.target.shippingCitta?.value.trim() || null) : null,
      shippingProvincia: differentAddress ? DOMPurify.sanitize(event.target.shippingProvincia?.value.trim() || null) : null,
      shippingCap: differentAddress ? DOMPurify.sanitize(event.target.shippingCap?.value.trim() || null) : null,
      
    };
    const resetForm = () => {
      setNome('');
      setCognome('');
      setCodiceFiscale('');
      setDataNascita('');
      setNatoA('');
      setProvinciaNascita('');
      setComuneResidenza('');
      setProvinciaResidenza('');
      setCap('');
      setIndirizzo('');
      setProfessione('');
      setTitoloStudio('');
      setEmail('');
      setCellulare('');
      setCartaIdentita('');
      setTesseraOption('ritiro');
      setPaymentMethod('bonifico');
      setPrivacy(false);
      setConsenso(false);
      setDifferentAddress(false);
      setShippingNome('');
      setShippingCognome('');
      setShippingIndirizzo('');
      setShippingAppartamento('');
      setShippingCitta('');
      setShippingProvincia('');
      setShippingCap('');
    };
    try {
      const response = await axios.post('/api/hfKkzTmRvrMPtQEx2jZ84q', sanitizedData);
      if (response.data.success) {
        // Mostra il messaggio di successo e svuota i campi
        setSuccessMessage('Dati inviati con successo!');
        setTimeout(() => setSuccessMessage(''), 5000); // Rimuovi il messaggio dopo 5 secondi
        resetForm();
      } else {
        alert('Errore durante l\'invio dei dati.');
      }
    } catch (error) {
      console.error(error);
      alert('Errore durante invio dei dati.');
    } finally {
      setIsLoading(false); // Disattiva lo stato di caricamento
    }
    
    
    const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);
      if (!isEmailValid(sanitizedData.email)) {
        alert('Email non valida.');
    return;
}

    const isPhoneValid = (phone) => /^\+?[0-9\s\-]{9,15}$/.test(phone);
      if (!isPhoneValid(sanitizedData.cellulare)) {
        alert('Numero di telefono non valido.');
    return;
} 
    const emailResponse = await axios.post(
      '/api/mQDBgpAM6xatPF432EjXrq', 
         sanitizedData,
          {
            headers: { 'Content-Type': 'application/json' },
          }
);
  };
  

  return (
    <div className="form-wrapper">
      {isLoading && (
      <div className="loading-overlay">
        <div className="spinner"></div>
        <p>Invio dati in corso...</p>
      </div>
    )}
    {successMessage && (
      <div className="success-message">
        <p>{successMessage}</p>
      </div>
    )}
      <div className="sostienici-form-container">
        <h2 className="h2Tess"> I miei dati (* campi obbligatori)</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <div className="personal-info">
              <div className="input-group">
                <label>Nome *</label>
                <i className="fas fa-user"></i>
                <input type="text" 
                  name="nome" 
                  placeholder="Nome" 
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Cognome *</label>
                <i className="fas fa-user"></i>
                <input type="text" 
                  name="cognome" 
                  placeholder="Cognome" 
                  required
                  value={cognome}
                  onChange={(e) => setCognome(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Nato a * (Se nato all'estero inserire solo il nome dello stato di nascita)</label>
                <i className="fas fa-map-marker-alt"></i>
                <input type="text" 
                  name="natoA" 
                  placeholder="Città di nascita" 
                  required
                  value={natoA}
                  onChange={(e) => setNatoA(e.target.value)}
                  />
              </div>
              <div className="input-group">
                <label>Provincia di *</label>
                <i className="fas fa-map"></i>
                <select 
                  name="provinciaNascita" 
                  required
                  value={provinciaNascita}
                  onChange={(e) => setProvinciaNascita(e.target.value)}>
                  <option value="">Seleziona Provincia</option>
                  {provinceTutte.map((region) => (
                    <optgroup key={region.label} label={region.label}>
                      {region.options.map((province) => (
                        <option key={province} value={province}>{province}</option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label>In data (gg/mm/AA) *</label>
                <i className="fas fa-calendar-alt"></i>
                <input type="date" 
                  name="dataNascita"  
                  max={getMaxDate()} 
                  value={dataNascita}
                  onChange={(e) => setDataNascita(e.target.value)}
                  required />
              </div>
              <div className="input-group">
                <label>Residente nel Comune di *</label>
                <i className="fas fa-home"></i>
                <input 
                  type="text" 
                  name="comuneResidenza" 
                  placeholder="Comune di residenza" 
                  required
                  value={comuneResidenza}
                  onChange={(e) => setComuneResidenza(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Provincia di *</label>
                <i className="fas fa-map"></i>
                <select 
                  name="provinciaResidenza" 
                  required
                  value={provinciaResidenza}
                  onChange={(e) => setProvinciaResidenza(e.target.value)}>
                  <option value="">Seleziona Provincia</option>
                  {provinces.map((region) => (
                    <optgroup key={region.label} label={region.label}>
                      {region.options.map((province) => (
                        <option key={province} value={province}>{province}</option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label>C.A.P. *</label>
                <i className="fas fa-mail-bulk"></i>
                <input 
                  type="text" 
                  name="cap" 
                  placeholder="CAP" 
                  required
                  value={cap}
                  onChange={(e) => setCap(e.target.value)}  />
              </div>
              <div className="input-group">
                <label>Indirizzo e numero civico (Se estero inserire anche il nome dello stato)*</label>
                <i className="fas fa-map-signs"></i>
                <input 
                  type="text" 
                  name="indirizzo" 
                  placeholder="Via/Piazza e Numero Civico" 
                  required
                  value={indirizzo}
                  onChange={(e) => setIndirizzo(e.target.value)}/>
              </div>
              <div className="input-group">
                <label>Professione: </label>
                <i className="fas fa-briefcase"></i>
                <input 
                  type="text" 
                  name="Professione" 
                  placeholder="Impiegato"
                  value={professione}
                  onChange={(e) => setProfessione(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Titolo di studio: </label>
                <i className="fas fas fa-graduation-cap"></i>
                <input 
                  type="text" 
                  name="Titolo di Studio" 
                  placeholder="Diploma"
                  value={titoloStudio}
                  onChange={(e) => setTitoloStudio(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Email *</label>
                <i className="fas fa-envelope"></i>
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Email" 
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Recapito telefonico *</label>
                <i className="fas fa-phone"></i>
                <input 
                  type="tel" 
                  name="cellulare" 
                  placeholder="Telefono" 
                  required
                  value={cellulare}
                  onChange={(e) => setCellulare(e.target.value)} />
              </div>

              
              <CodiceFiscaleCalculator
                nome={nome}
                cognome={cognome}
                dataNascita={dataNascita}
                natoA={natoA}
                provinciaNascita={provinciaNascita}
                onCodiceFiscaleChange={(calcolatoCodiceFiscale) => 
                setCodiceFiscale(calcolatoCodiceFiscale)}
/>


          <div className="input-group">
                <label>N Carta d'identita' *</label>
                <i className="fas fa-id-card"></i>
                <input
                  type="text"
                  name="cartaIdentita"
                  placeholder="N Carta Identità"
                  required
                  value={cartaIdentita}
                  onChange={(e) => setCartaIdentita(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
          </div>

            <div className="payment-info">
              <h3>Pagamento</h3>
              <p className="pTess">Tessera:</p>
              <label>
                <input
                  type="radio"
                  name="supporterType"
                  value="sostenitore"
                  checked={supporterType === 'sostenitore'}
                  onChange={() => handleSupporterTypeChange('sostenitore', 15)}
                />
                Sostenitore - 15,00 €
              </label>

              <p className="pTess">Scegli come ottenere la tessera:</p>
              <label>
                <input
                  type="radio"
                  name="tesseraOption"
                  value="ritiro"
                  checked={tesseraOption === 'ritiro'}
                  onChange={() => handleTesseraOptionChange('ritiro')}
                  required
                />
                Ritiro la tessera dal referente territoriale in alternativa ti verra' recapitata per posta
              </label> <br />
              

              {tesseraOption === 'ritiro' && (
                <label>
                  <input
                    type="checkbox"
                    checked={differentAddress}
                    onChange={() => setDifferentAddress(!differentAddress)}
                  />
                  Spedire la tessera ad un indirizzo diverso?
                </label>
              )}
                {differentAddress && (
                <div className="shipping-address">
                  <h4>Indirizzo di Spedizione Alternativo</h4>
                  <div className="input-group-s">
                    <label>Nome *</label>
                    <i className="fas fa-user"></i>
                    <input 
                      type="text" 
                      name="shippingNome" 
                      placeholder="Nome" 
                      required
                      value={shippingNome}
                      onChange={(e) => setShippingNome(e.target.value)} />
                  </div>
                  <div className="input-group-s">
                    <label>Cognome *</label>
                    <i className="fas fa-user"></i>
                    <input 
                      type="text" 
                      name="shippingCognome" 
                      placeholder="Cognome" 
                      required
                      value={shippingCognome}
                      onChange={(e) => setShippingCognome(e.target.value)} />
                  </div>
                  <div className="input-group-s">
                    <label>Via e numero (Se estero inserire anche il nome dello stato)*</label>
                    <i className="fas fa-map-signs"></i>
                    <input 
                      type="text" 
                      name="shippingIndirizzo" 
                      placeholder="Via/Piazza e Numero Civico" 
                      required
                      value={shippingIndirizzo}
                      onChange={(e) => setShippingIndirizzo(e.target.value)} />
                  </div>
                  <div className="input-group-s">
                    <label>Appartamento, suite, unità, ecc.</label>
                    <i className="fas fa-building"></i>
                    <input 
                      type="text" 
                      name="shippingAppartamento" 
                      placeholder="Appartamento, suite, unità, ecc."
                      value={shippingAppartamento}
                      onChange={(e) => setShippingAppartamento(e.target.value)} />
                  </div>
                  <div className="input-group-s">
                    <label>Città *</label>
                    <i className="fas fa-city"></i>
                    <input 
                      type="text" 
                      name="shippingCitta" 
                      required
                      value={shippingCitta}
                      onChange={(e) => setShippingCitta(e.target.value)} />
                  </div>
                  <div className="input-group-s">
                    <label>Provincia *</label>
                    <i className="fas fa-map"></i>
                    <select
                      name="shippingProvincia" 
                      required
                      value={shippingProvincia}
                      onChange={(e) => setShippingProvincia(e.target.value)}>
                      <option value="">Seleziona Provincia</option>
                      {provinces.map((region) => (
                        <optgroup key={region.label} label={region.label}>
                          {region.options.map((province) => (
                            <option key={province} value={province}>{province}</option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
                  </div>
                  <div className="input-group-s">
                    <label>C.A.P. *</label>
                    <i className="fas fa-mail-bulk"></i>
                    <input 
                      type="text" 
                      name="shippingCap" 
                      required
                      value={shippingCap}
                      onChange={(e) => setShippingCap(e.target.value)} />
                  </div>
                </div>
              )}
             <p className="pTess">Metodo di pagamento:</p>
                {tesseraOption === 'ritiro' && (
                  <label>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="contanti"
                      checked={paymentMethod === 'contanti'}
                      onChange={() => setPaymentMethod('contanti')}
                      required
                    />
                    Pagamento in contanti alla consegna
                  </label>
                )}
                <label><br />
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="bonifico"
                    checked={paymentMethod === 'bonifico'}
                    onChange={() => setPaymentMethod('bonifico')}
                    required
                  />
                  Bonifico Bancario
                </label> 
                {paymentMethod === "bonifico" && (
                  <div className="donation-section">
                    <h4>Aggiungi una sovvenzione volontaria</h4>
                    <div className="donation-options">
                      {[5, 10, 15, 20].map((amount) => (
                        <div key={amount} className="donation-radio">
                          <input
                            type="radio"
                            id={`donation-${amount}`}
                            name="donation"
                            value={amount}
                            checked={donation === amount}
                            onChange={() => handleAddDonation(amount)}
                            
                          />
                          <label htmlFor={`donation-${amount}`}>+€{amount}</label>
                        </div>
                      ))}
                    </div>
                    <div className="custom-donation">
                      <label htmlFor="custom-donation">Importo a tua scelta:</label>
                      <textarea
                        id="custom-donation"
                        value={customDonation}
                        onChange={handleCustomDonationChange}
                        placeholder="Inserisci importo"
                      />
                    </div>
                  </div>
                )}




              <p className="total-cost">Totale: {calculateTotalCost()},00 €</p>

              {paymentMethod === 'bonifico' && (
                <>
                  <h4>Dati bancari</h4>
                  <p className="pTess">Dati bancari per il bonifico:</p>
                  <div className="pIban">
                    <p>Patto per il Nord</p>
                    <p>Banco BPM - Milano</p>
                    <p>IBAN IT57 N 05034 01636 000000031728</p>
                    <p>Causale: Nome: {nome} Cognome: {cognome} - Tesseramento</p>
                  </div>
                </>
              )}

              <label>
                <input type="checkbox" name="privacy" required />
                Autorizzo il trattamento dei dati personali ai sensi del GDPR.
              </label> <br />

              <label>
                <input type="checkbox" name="consenso" required />
                Acconsento a ricevere comunicazioni.
              </label>
             


              <button type="submit" className="submit-button">INVIA</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactFormTesseramento;





