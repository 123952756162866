import React from 'react';
import '../styles/Social.css';

const socialLinks = [
  {
    name: 'Facebook',
    description: 'Segui Patto per il Nord su Facebook!',
    icon:'/assets/icon/facebook.png',
    link: 'https://www.facebook.com/pattoperilnord1/',
  },
  {
    name: 'Instagram',
    description: 'Segui il profilo anche su Instagram di Patto per il Nord',
    icon: '/assets/icon/instagram.png',
    link: 'https://www.instagram.com/pattoperilnord/',
  },
  {
    name: 'X',
    description: 'Segui l’account X di Patto per il Nord.',
    icon: '/assets/icon/x.png',
    link: 'https://x.com/Pattoperilnord',
  },
  {
    name: 'Telegram',
    description: 'Segui il canale Telegram di Patto per il Nord.',
    icon: '/assets/icon/telegram.png',
    link: 'https://t.me/pattoperilnord',
  },
  {
    name: 'YouTube',
    description: 'Iscriviti al nostro canale YouTube per restare aggiornati su tutti i nostri nuovi contenuti',
    icon: '/assets/icon/youtube.png',
    link: 'https://www.youtube.com/@PattoperilNord',
  },
];

const Socials = () => {
  return (
    <div className="social-media-section">
      {socialLinks.map((social, index) => (
        <div key={index} className="social-card">
          <h3>{social.name}</h3>
          <p>{social.description}</p>
          <a href={social.link} className="social-button">
            <img src={social.icon} alt={`${social.name} icon`} className="social-icon" />
            Scopri di più
          </a>
        </div>
      ))}
    </div>
  );
};
export default Socials;