import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/RegionPage.css';
import Navbar from '../components/Navbar';
import CookiePopup from '../components/CookiePopup';
import Footer from '../components/Footer';

const RegionPage = () => {
  const { region } = useParams(); // Ottieni il parametro regione dall'URL
  const [events, setEvents] = useState([]); // Stato per gli eventi
  const [error, setError] = useState(null); // Stato per gestire errori
  const [loading, setLoading] = useState(false); // Stato per il caricamento

  const formatDateToEuropean = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-'); // Supponendo che la data sia in formato YYYY-MM-DD
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const apiURL = `/api/NmXQPzbauVT7vfegA64C8n?action=get&region=${encodeURIComponent(region.toLowerCase())}`; // URL API con parametro regione

      setLoading(true); // Inizia il caricamento
      setError(null); // Resetta eventuali errori precedenti
      try {
        const response = await fetch(apiURL);
        if (!response.ok) {
          throw new Error('Errore nel caricamento degli eventi');
        }

        const data = await response.json();
        if (data.success) {
          setEvents(data.data || []); // Imposta gli eventi ricevuti
        } else {
          setError(data.error || 'Errore sconosciuto dal server');
        }
      } catch (error) {
        setError(error.message || 'Errore di connessione');
        console.error('Errore fetch eventi:', error); // Log errore per debug
      } finally {
        setLoading(false); // Fine caricamento
      }
    };

    fetchEvents();
  }, [region]); // Effettua la richiesta ogni volta che cambia la regione

  return (
    <div className="page-container">
      <Navbar />
      <div className="region-page">
        <h1>Eventi in {region.charAt(0).toUpperCase() + region.slice(1)}</h1>
        {loading ? (
          <p>Caricamento eventi...</p>
        ) : error ? (
          <p className="error-message">Errore: {error}</p>
        ) : events.length === 0 ? (
          <p>Nessun evento in programma per questa regione.</p>
        ) : (
          <div className="event-list">
            {events.map((event) => (
              <div key={event.id} className="event-card">
                <h2>{event.title}</h2>
                <p><strong>Data:</strong> {formatDateToEuropean(event.date)}</p>
                <p><strong>Luogo:</strong> {event.location}</p>
                <p>{event.description}</p>
                {event.image && (
                  <img src={event.image} alt={event.title} className="event-image" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default RegionPage;


