import React, { useState, useEffect } from 'react';
import '../styles/Fondatori.css';

const FondatoriPage = () => {
  const [fondatoriData, setFondatoriData] = useState([]); // Stato per il JSON
  const [loading, setLoading] = useState(true); // Stato per il caricamento

  useEffect(() => {
    // Caricamento dinamico del JSON
    fetch('/api/x4MJrTb7uadSC6hWzHePDQ', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Errore nel caricamento del JSON');
        return response.json();
      })
      .then((data) => {
        setFondatoriData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Errore nel caricamento:', error);
        setLoading(false);
      });
  }, []);

  return (
    <section className="rappresentanti-container">
      <div className="navbar-spacing"></div>
      <h1 className="rappresentanti-title">I Padri Fondatori</h1>
      <div className="navbar-spacing"></div>

      {loading ? (
        <p>Caricamento in corso...</p>
      ) : (
        <div className="cards-container">
          {fondatoriData.map((person, index) => (
            <div className="card" key={index}>
              <img
                src={person.image}
                alt={person.name}
                className="card-image"
                loading="lazy"
              />
              <div className="card-content">
                <h2 className="card-name">{person.name}</h2>
                <p className="card-role">{person.role}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default FondatoriPage;
