import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HomeProvvisoria from '../components/HomeProvvisoria'
import CookiePopup from '../components/CookiePopup';
import ManifestiHome from '../components/ManifestiHome';
import Socials from '../components/Social';
import Mappa from '../components/Mappa';


const Home = () => {
  return (
    <div>
      <Navbar />
      <HomeProvvisoria />
      <ManifestiHome />
      <Mappa />
      <Socials />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default Home;
